<!--
 * @Author: wyq
 * @Date: 2021-09-27 09:20:54
 * @LastEditTime: 2021-10-27 11:24:40
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\views\testingCenter\previewTesting.vue
-->
<template>
  <el-container class="start-testing round-bg">
    <el-header>
      <div class="testing-header">
        <div class="d-flex">
          <sequence-bar class="flex-grow-1" inline :currentIndex="currentQuestionIndex" :value="questions" preview
            finish @clickSquare="clickSquare"></sequence-bar>
          <div class="flex-right">
            <v-btn color="primary" @click="goBack">返回</v-btn>
          </div>
        </div>
      </div>
    </el-header>
    <el-main>
      <question-preview :questionInfo="currentQuestion" @reasonChange="reasonChange"></question-preview>
    </el-main>
  </el-container>
</template>
<script>
import sequenceBar from '@/components/testing/sequenceBar.vue'
import questionPreview from '@/components/testing/questionPreview.vue'
export default {
  components: {
    sequenceBar,
    questionPreview,
  },
  data() {
    return {
      show: true,
      fillupAnswer: '',
      questions: [],
      currentQuestionIndex: 0,
    }
  },
  computed: {
    pageType() {
      return this.$route.query.type
    },
    examinationId() {
      return this.$route.query.examinationId
    },
    finishId() {
      return Number(this.$route.query.id)
    },
    currentQuestion() {
      if (this.questions?.length) {
        return this.questions[this.currentQuestionIndex]
      } else {
        return {}
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getFinishQuestion() {
      let questionList = []
      if (this.pageType == 'train') {
        let params = {
          examinationId: this.examinationId,
        }
        const { reportQuestions } =
          await this.$service.getTrainExaminationReport(params)
        questionList = reportQuestions
      } else if (this.pageType == 'error') {
        let params = {
          examinationId: this.examinationId,
        }
        const { reportQuestions } =
          await this.$service.getErrorExaminationReport(params)
        questionList = reportQuestions
      } else if (this.pageType == 'finish') {
        let params = {
          id: this.finishId,
        }
        const { finishQuestions } = await this.$service.getFinishQuestion(
          params
        )
        questionList = finishQuestions
      } else if (this.pageType == 'teacher') {
        let params = {
          examinationId: this.examinationId,
        }
        const { reportQuestions } = await this.$service.getFamousTeacherTrainReport(params)
        questionList = reportQuestions
      } else if (this.pageType == 'teacherFinish') {
        let params = {
          trainNodeId: this.finishId
        };
        const { finishQuestions } = await this.$service.getCompletedQuestions(params);
        questionList = finishQuestions;
      } else {
        return
      }
      let i
      for (i = 0; i < questionList.length; i++) {
        const finishInfo = questionList[i]
        const questionInfo = await this.getQuestionInfo(finishInfo.questionId)
        const errorInfo = await this.getQuestionErrorInfo(finishInfo.questionId)
        let result = {
          ...finishInfo,
          ...questionInfo,
          ...errorInfo,
        }
        // 选择题选项显示对错
        if (result.type == 1) {
          result.myAnswer=Number(finishInfo.answer)
        }else if(result.type == 4){
          if (finishInfo.answer === '') {
						result.myAnswer = [];
					} else {
						result.myAnswer = finishInfo.answer.split(',').map(i => Number(i));
					}
        }
        this.$set(this.questions, i, result)
      }
    },
    // 获取题目详情
    async getQuestionInfo(questionId) {
      let params = {
        questionID: questionId,
      }
      const itemQuestion = await this.$service.getQuestionInfo(params)
      let result = {
        ...this.$formattedData(itemQuestion),
      }
      return Promise.resolve(result)
    },
    // 获取错因信息
    async getQuestionErrorInfo(questionId) {
      let params = {
        questionId: questionId,
      }
      const res = await this.$service.getQuestionErrorInfo(params)
      res.errorReasonId = res.errorReasonId ? res.errorReasonId : []
      res.errorReason = this.$getTag(res.errorReasonId)
      return Promise.resolve(res)
    },
    // 题目切换
    clickSquare(index) {
      this.currentQuestionIndex = index
    },
    // 修改错因后刷新数据
    async reasonChange() {
      const errorInfo = await this.getQuestionErrorInfo(
        this.currentQuestion.questionId
      )
      this.$set(this.questions, this.currentQuestionIndex, {
        ...this.currentQuestion,
        ...errorInfo,
      })
    },
  },
  mounted() {
    const questionIndex = this.$route.query.index
    if (questionIndex) {
      this.currentQuestionIndex = Number(questionIndex)
    }
    this.getFinishQuestion()
  },
}
</script>
<style lang="scss" scoped>
.start-testing {
  .testing-header {
    background-color: #fff;

    .flex-right {
      min-width: 185px;
      padding: 14px 44px;
      border-left: 1px solid #fafafa;
      text-align: center;

      .v-btn {
        min-width: 100px;
      }
    }
  }

  .el-main {
    padding: 12px;
  }
}
</style>